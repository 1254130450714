<template>
    <div class="row">
        <div class="col-12">
            <div class="box">
				<CustomTable
					id_table="localisation_semence"
					ref="table"
					:items="localisations"
					:busy.sync="table_busy"
					primaryKey="semencelocalisation_id"
				/>

				<ModalLocSemence 
					ref="modalLocalisation"
					:refresh_table.sync="refresh_table"
					:localisation="selected"
				/>

				<ModalReorganizeLocSemence 
					ref="modalReorganize"
					:refresh_table.sync="refresh_table"
					:localisation="reorganize_selected"
				/>
            </div>
        </div>
    </div>
</template>

<script>
import StockSemence from '@/mixins/StockSemence'
import Navigation from "@/mixins/Navigation.js"
import draggable from "vuedraggable";

export default {
	name: 'Cuves',
	mixins: [StockSemence, Navigation],
	data() { 
		return {
			table_busy: true,
	        localisations: [],
			events_tab: {
	            'TableAction::goToAddLocSemence': () => { 
	            	this.selected = null
	            	this.openModalLocalisation() 
	            },
	            'TableAction::goToEditLocSemence': (loc) => { 
	            	this.selected = loc[0]
	            	this.openModalLocalisation() 
	            },
	            'TableAction::goToReorganizeChildren': (loc) => {
	            	this.reorganize_selected = loc[0]
	            	this.openModalReorganize()
	            },
	            'TableAction::goToReorganizeParent': () => {
	            	this.reorganize_selected = {children: this.localisations.filter(loc => loc.semencelocalisation_parent == 0)}
	            	this.openModalReorganize()
	            },
	            'TableAction::goToDeleteLocSemence': this.deleteLocalisation
	        },
	        selected: {},
	        reorganize_selected: {},
	        refresh_table: false
	    }
	},
	created() {
		this.reloadLocSemence()
	},
	methods: {
		openModalLocalisation() {
			this.$refs.modalLocalisation.openModal()
		},
		openModalReorganize() {
			this.$refs.modalReorganize.openModal()
		},
		async reloadLocSemence() {
			this.selected = {}
			this.reorganize_selected = {}
			this.table_busy = true
			const localisations = await this.getLocalisationSemence()
			this.localisations = localisations.filter(loc => !loc.semencelocalisation_is_floor)
			this.table_busy = false
		},

		async deleteLocalisation(loc) {
			let tab_promises = []
			for(let i in loc) {
				tab_promises.push(this.deleteLocSemence(loc[i].semencelocalisation_id))
			}

			Promise.all(tab_promises)
			.then(() => {
				this.$refs.table.unselectAll()
				this.reloadLocSemence()
				this.successToast("toast.info_save_succes")
			})
			.catch(() => {
				this.failureToast()
			})
		},
	},
	watch: {
		refresh_table() {
			this.reloadLocSemence()
		}
	},
	components: {
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		ModalLocSemence: () => import('@/components/Monte/ModalLocSemence'),
		ModalReorganizeLocSemence: () => import('@/components/Monte/ModalReorganizeLocSemence')
	}
};
</script>
